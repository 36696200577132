<template>
    <div>
		<h1>Reeglid</h1>
		<i>Täidetakse automaatselt kannete importimisel</i>

		<b-modal v-if="editModal.rule"
				 v-model="editModal.visible"
				 size="lg"
				 title="Muuda reeglit"
				 no-close-on-backdrop
				 hide-footer
		>
			<b-row>
				<b-col>
					<b>Otsingutingimused</b>
					<template v-for="(rule,idx) in editModal.rule.rules">
						<b-row v-if="idx > 0">
							<b-col class="text-center">
								JA
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-row class="mt-1">
									<b-col cols="20">
										<b-select :options="fields" v-model="rule.field" @change="matchTransactions"></b-select>
									</b-col>
									<b-col cols="20">
										<b-select :options="operators" v-model="rule.operator" @change="matchTransactions"></b-select>
									</b-col>
									<b-col>
										<b-input-group v-for="(keyword, kIdx) in rule.keywords" :key="kIdx">
											<template v-if="kIdx > 0" #prepend>
												<b-input-group-text>
													VÕI
												</b-input-group-text>
											</template>
											<b-form-input v-model="keyword.text" @change="matchTransactions"></b-form-input>
											<b-input-group-append>
												<b-button v-if="kIdx > 0"
														  @click="deleteKeyword(rule, kIdx)"
														  variant="outline-primary"
														  size="sm"
												>
													<b-icon icon="trash"></b-icon>
												</b-button>
												<b-button @click="addKeyword(rule)"
														  variant="outline-primary"
														  size="sm"
												>
													<b-icon icon="plus"></b-icon>
												</b-button>
											</b-input-group-append>
										</b-input-group>
									</b-col>
									<b-col cols="10" class="text-right">
										<b-button @click="deleteRuleRule(idx)" variant="primary">
											<b-icon icon="trash"></b-icon>
										</b-button>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</template>
					<b-row>
						<b-col>
							<b-button variant="primary" @click="addRuleRule" class="m-1">
								<b-icon icon="plus"></b-icon>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row class="mt-4">
				<b-col>
					<b>Tegevused</b>
					<b-row
							v-for="(a,idx) in editModal.rule.actions" :key="idx"
					>
						<b-col>
							<b-row>
								<b-col cols="20">
									<b-select
										:options="actions"
										v-model="a.action"
										class="m-1"
									></b-select>
								</b-col>
								<b-col>
									<v-select v-if="$store.state.tags.list"
											  label="text"
											  :options="$store.state.tags.list"
											  v-model="a.tag"
											  style="min-width: 300px; line-height: 1.6em;"
											  class="m-1"
									>
										<template #option="{ text, value, depth, is_leaf, prefix }">
											<em v-for="i in depth" style="color:#d0d0d0;">- </em>
											<em v-if="is_leaf">{{ prefix }} {{ text }}</em>
											<b v-else>{{ prefix }} {{ text }}</b>
										</template>
									</v-select>
								</b-col>
								<b-col cols="10" class="text-right">
									<b-button @click="deleteRuleAction(idx)" variant="primary" class="mt-1">
										<b-icon icon="trash"></b-icon>
									</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-button v-if="editModal.rule.actions.length < 1"
									  variant="primary"
									  @click="addRuleAction"
									  class="m-1"
							>
								<b-icon icon="plus"></b-icon>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<b-form-checkbox v-model="editModal.addToExisting" switch>
						Rakenda kohe leitud kannetele
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="text-right">
					<hr>
					<b-button
							variant="light"
							@click="editModal.visible = false"
					>
						Tühista
					</b-button>
					<b-button
							variant="primary"
							class="ml-2"
							@click="saveRule"
							:disabled="!ruleCanBeSaved()"
					>
						Salvesta
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ul>
						<li v-for="(tr, idx) in matchingTransactions">
							{{ tr.date }} | {{ tr.amount }}€ | {{ tr.receiver_sender }} | {{ tr.description }}
						</li>
					</ul>
					<div v-if="matchingSpinner" class="text-center text-primary my-2">
						<b-spinner variant="primary" class="align-middle"></b-spinner>
					</div>
				</b-col>
			</b-row>
		</b-modal>

		<b-list-group v-if="$store.state.rules && $store.state.tags.tags">
			<b-list-group-item variant="dark">
				<b-row>
					<b-col cols="5">
						<b-row class="text-right">
							<b-col>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="55">
						<b-row class="text-left">
							<b-col>
								<b>Määramisfiltrid</b>
							</b-col>
						</b-row>
					</b-col>
					<b-col>
						<b-row class="text-left">
							<b-col>
								<b>Tegevused</b>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="20">
						<b-button variant="primary"
								  class="mr-2"
								  size="sm"
								  @click="addRule"
						>
							Lisa uus
						</b-button>
					</b-col>
				</b-row>
			</b-list-group-item>
			<b-list-group-item
					v-for="(rule, rIdx) in $store.state.rules"
					:key="rIdx"
			>
				<b-row>
					<b-col cols="5" class="text-right">
						<b>{{ rIdx + 1 }}.</b>
					</b-col>
					<b-col cols="55" class="mr-1 border-right">
						<template v-for="(r,idx) in rule.rules">
							<b-row v-if="idx > 0">
								<b-col class="text-center">
									<b-badge variant="light"> ja </b-badge>
								</b-col>
							</b-row>
							<b-row class="text-left">
								<b-col>
									<b-badge variant="light">
										{{ valueToText(r.field, fields) }}
									</b-badge>
									<b-badge variant="light" class="ml-2">
										{{ valueToText(r.operator, operators) }}
									</b-badge>
									<template v-for="(keyword, kIdx) in r.keywords">
										<b-badge v-if="kIdx > 0" variant="light">või</b-badge>
										<b-badge variant="dark" class="ml-2">
											{{ keyword.text }}
										</b-badge>
									</template>
								</b-col>
							</b-row>
						</template>
					</b-col>
					<b-col class="ml-1">
						<b-row class="text-left"
								v-for="(a,idx) in rule.actions" :key="idx"
						>
							<b-col>
								<b-badge variant="light">
									{{ valueToText(a.action, actions) }}
								</b-badge>
								<b-badge variant="dark" class="ml-2">
									{{ $store.state.tags.tags[a.tag_id].name }}
								</b-badge>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="20">
						<b-button variant="primary" @click="deleteRule(rule)" size="sm" class="mr-1">
							<b-icon icon="trash"></b-icon>
						</b-button>
						<b-button variant="primary" @click="editRule(rule)" size="sm">
							<b-icon icon="pencil"></b-icon>
						</b-button>
					</b-col>
				</b-row>
			</b-list-group-item>
			<b-list-group-item class="text-right">
				<b-button variant="primary" size="sm" class="mr-4" @click="addRule">Lisa uus</b-button>
			</b-list-group-item>
		</b-list-group>

		<div v-else class="text-center text-primary my-2">
			<b-spinner variant="primary" class="align-middle"></b-spinner>
		</div>

    </div>
</template>

<script>

	import _ from 'lodash';
	import Swal from 'sweetalert2';
	import axios from 'axios';

	export default {
		data () {
			return {
				editModal: {
					visible: false,
					rule: null,
					addToExisting: true,
				},
				matchingSpinner: false,
				matchingTransactions: [],
				rules: [],
				fields: [
					{value: 'description', text: 'Kirjeldus'},
					{value: 'receiver_sender', text: 'Saaja / Saatja'},
					{value: 'amount', text: 'Summa'},
				],
				operators: [
					{value: '=', text: '='},
					{value: 'like', text: 'Sisaldab'},
					{value: '>=', text: '>='},
					{value: '<=', text: '<='},
				],
				actions: [
					{value: 'add_tag', text: 'Lisa silt'},
				]
			}
		},
		created() {
			this.$store.dispatch('loadRules')
			this.$store.dispatch('loadTags');
		},
		methods: {
			valueToText (field, collection) {
				return _.find(collection, {'value': field}).text;
			},
			addRule () {
				this.matchingTransactions = [];
				this.editModal.rule = {id: null, rules: [], actions: []};
				this.editModal.visible = true;
			},
			addRuleRule () {
				this.matchingTransactions = [];
				this.editModal.rule.rules.push({field: null, operator: null, keywords: [{text: null}]});
			},
			addRuleAction () {
				this.editModal.rule.actions.push({action: null, tag: null, tag_id: null});
			},
			addKeyword(rule) {
				this.matchingTransactions = [];
				rule.keywords.push({text: null});
			},
			deleteKeyword(rule, idx) {
				rule.keywords.splice(idx, 1);
				this.matchTransactions();
			},
			deleteRuleRule (idx) {
				this.editModal.rule.rules.splice(idx, 1);
				this.matchTransactions();
			},
			deleteRuleAction (idx) {
				this.editModal.rule.actions.splice(idx, 1);
			},
			editRule (rule) {
				this.matchingTransactions = [];
				this.editModal.rule = JSON.parse(JSON.stringify(rule));
				this.editModal.visible = true;
			},
			matchTransactions() {
				let canMatch = true;
				this.matchingTransactions = [];
				this.editModal.rule.rules.forEach(function(rule) {
					if (!rule.field || !rule.operator) {
						canMatch = false;
					}
					rule.keywords.forEach(function(keyword) {
						!keyword.text ? canMatch = false : null;
					});
				});
				if (canMatch) {
					this.matchingSpinner = true;
					axios.post('/match_transactions', {rules: this.editModal.rule.rules}).then(({data}) => {
						this.matchingTransactions = data;
						this.matchingSpinner = false;
					});
				}
			},
			ruleCanBeSaved() {
				let allOk = true;
				if (this.editModal.rule.actions.length == 0 || this.editModal.rule.rules.length == 0) {
					allOk = false;
				}
				this.editModal.rule.rules.forEach(function(rule) {
					if (rule.field == null || rule.operator == null) {
						allOk = false;
					}
					rule.keywords.forEach(function(keyword) {
						keyword.text == null ? allOk = false : null;
					});
				});
				this.editModal.rule.actions.forEach(function(action) {
					if (action.tag == null || action.action == null) {
						allOk = false;
					}
				});
				return allOk;
			},
			saveRule () {
				let postData = {
					rule: this.editModal.rule,
					addToExisting: this.editModal.addToExisting,
					matchingTransactions: this.matchingTransactions
				};
				axios.post('/rule', postData).then(({data}) => {
					this.editModal.visible = false;
					this.$store.dispatch('loadRules', data);
					Swal.fire({
						title: 'Salvestatud',
						confirmButtonText: 'OK',
						confirmButtonColor: '#6f42c1',
						icon: 'success',
					});
				});
			},
			deleteRule (rule) {
				Swal.fire({
					title: 'Soovid reegli kustutada?',
					icon: 'error',
					showCancelButton: true,
					confirmButtonColor: '#6f42c1',
					confirmButtonText: 'Kustuta',
				}).then((result) => {
					if (result.isConfirmed) {
						axios.delete('/rule/'+rule.id).then(({data}) => {
							this.$store.dispatch('loadRules', data);
							Swal.fire({
								title: 'Kustutatud',
								confirmButtonText: 'OK',
								confirmButtonColor: '#6f42c1',
								icon: 'success',
							});
						});
					}
				});
			}
		}
	}
</script>

<style>
	.vs--searchable .vs__dropdown-toggle {
		height: 38px;
	}
</style>
